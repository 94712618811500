<template>
  <b-card no-body>
    <b-overlay :show="loading">
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col><div>Mading {{ appShortName }}</div></b-col>
            <b-col>
              <div align="right" v-if="allowCreate()">
                <b-button
                  variant="outline-primary"
                  v-b-modal.modal-lg
                  @click="ModalShow()"
                  class="btn-icon"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button
                >
              </div>
              <!-- size sm,lg -->

              <b-modal
                v-model="ModalUploadThumbnail"
                id="modal-upload"
                centered
                size="lg"
                :title="titleModal"
              >
                <b-card-text>
                  <b-form>
                    <b-row>
                      <b-col md="12">
                        <b-form-group label="Thumbnail" label-for="Thumbnail">
                          <b-form-file
                            id="Thumbnail"
                            v-model="form.thumbnail"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            no-drop
                            accept="image/*"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="UploadThumbnail"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click.prevent="tutupModal"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
              <b-modal
                v-model="Modal"
                id="modal-tambah"
                centered
                size="lg"
                :title="titleModal"
              >
                <b-card-text>
                  <b-form>
                    <b-row>
                      <b-col md="6">
                        <b-form-group label="Kategori" label-for="kategori">
                          <b-form-select
                            v-model="form.category_id"
                            :options="optcategory_id"
                          />
                        </b-form-group>

                        <!-- <b-form-group label="Tipe" label-for="Tipe">
                          <b-form-select v-model="form.type" :options="optTipe" disabled/>
                        </b-form-group> -->
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Judul" label-for="Judul">
                          <b-form-input
                            id="Judul"
                            v-model="form.title"
                            placeholder="Judul"
                          />
                        </b-form-group>
                        <!-- <b-form-group label="Slug" label-for="Slug">
                          <b-form-input
                            id="Slug"
                            v-model="form.slug"
                            placeholder="Slug"
                          />
                        </b-form-group> -->
                        <!-- <b-form-group label="Thumbnail" label-for="Thumbnail">
                          <b-form-file
                            id="Thumbnail"
                            v-model="form.thumbnail"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            no-drop
                            accept="image/*"
                          />
                        </b-form-group> -->
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group label="Konten" label-for="Konten">
                          <quill-editor id="Konten" v-model="form.content" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="Tambah"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click.prevent="tutupModal"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col>
          </b-row>
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-50"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-30"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Tekan enter untuk cari"
                    @keydown.enter.prevent="getData()"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="
                        filter = '';
                        getData();
                      "
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :fields="fields"
                :items="artikel"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    v-if="allowUpdate() || allowDelete()"
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      target="_blank"
                      class="text-dark"
                      :href="getPathPreview(row.item.slug)"
                    >
                      <feather-icon icon="EyeIcon" class="mr-50" />
                      <span>Preview</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="ModalUpload(row.item)">
                      <feather-icon icon="UploadCloudIcon" class="mr-50" />
                      <span>Upload Thumbnail</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="allowUpdate()"
                      @click="ModalUbah(row.item)"
                    >
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="allowDelete()"
                      @click="ModalHapus(row.item)"
                    >
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <!-- <b-button
                    title="Upload Thumbnail"
                    variant="outline-warning"
                    class="btn-icon"
                    @click="ModalUpload(row.item)"
                  >
                    <feather-icon icon="CameraIcon"
                  /></b-button>
                  <b-button
                    variant="outline-info"
                    class="btn-icon"
                    @click="ModalUbah(row.item)"
                  >
                    <feather-icon icon="EditIcon"
                  /></b-button>

                  <b-button
                    v-if="
                      row.item.slug != 'tentang-kami' &&
                      row.item.slug != 'kebijakan-privasi' &&
                      row.item.slug != 'syarat-ketentuan'
                    "
                    variant="outline-danger"
                    class="btn-icon"
                    @click="ModalHapus(row.item)"
                  >
                    <feather-icon icon="TrashIcon"
                  /></b-button> -->
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell(show_details)="row">
                  <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                  >
                    <span class="vs-checkbox">
                      <span class="vs-checkbox--check">
                        <i class="vs-icon feather icon-check" />
                      </span>
                    </span>
                    <span class="vs-label">{{
                      row.detailsShowing ? "Hide" : "Show"
                    }}</span>
                  </b-form-checkbox>
                </template>
                <template #row-details="row">
                  <b-card>
                    <b-row class="mb-2">
                      <b-col md="4" class="mb-1">
                        <strong>Thumbnail : </strong><br /><b-img 
                          :src="row.item.thumbnail != null ? `${apiFile}/${row.item.thumbnail}` : `${require('@/assets/images/elp/no-image.jpg')}`"
                        style="width: 100%" :alt="row.item.thumbnail" />
                        <!-- <div class="my-2" v-if="preview_image">
                        <img :src="preview_image" style="width: 100px" alt="" />
                      </div> -->
                      </b-col>
                      <b-col md="8" class="mb-1">
                        <strong>Konten : </strong><br />
                        <quill-editor v-model="row.item.content" disabled />
                      </b-col>
                    </b-row>

                    <b-button
                      size="sm"
                      variant="outline-secondary"
                      @click="row.toggleDetails"
                    >
                      Hide Details
                    </b-button>
                  </b-card>
                </template>
              </b-table>
              <!-- <b-pagination
          v-if="record !== 0"
          :total="Math.ceil((total * 1) / itemsPerPage)"
          v-model="currentPage"
          @change="staf()"
        /> -->
              <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
          :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BImg,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormFile,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"; // eslint-disable-next-line
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BDropdownItem,
    BDropdown,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BImg,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    ToastificationContent,
    quillEditor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      artikel: [],
      form: {
        id: null,
        title: null,
        category_id: null,
        slug: null,
        type: "article",
        thumbnail: null,
        content: null,
      },
      //select
      optTipe: [
        { value: "page", text: "Page" },
        { value: "article", text: "Mading" },
      ],
      optcategory_id: [],
      //Modal
      titleModal: null,
      Modal: false,
      ModalUploadThumbnail: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "show_details", label: "#" },
        { key: "index", label: "No" },
        { key: "category.name", label: "Kategori", sortable: true },
        { key: "title", label: "Judul", sortable: true },
        // { key: "slug", label: "Slug", sortable: true },
        // { key: "type", label: "Tipe", sortable: true },
        // { key: "thumbnail", label: "Thumbnail", sortable: true },
        // { key: "content", label: "Konten", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "title", value: "Judul" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Kategori",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    getPathPreview(slug) {
      return `/artikel/detail/${slug}`;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    tutupModal() {
      this.Modal = false;
      this.ModalUploadThumbnail = false;
      this.resetForm();
      this.getData();
    },
    resetForm() {
      this.form = {
        id: null,
        title: null,
        category_id: null,
        slug: null,
        type: "article",
        thumbnail: null,
        content: null,
      };
    },
    ModalUpload(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "upload";
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Mading";
      this.Modal = true;
      this.getDataKategoriArtikel();
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.form.thumbnail = null;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Mading";
      this.Modal = true;
      this.getDataKategoriArtikel();
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          const payload = {
            id: data.id,
            fungsi: 1,
          };
          this.$store
            .dispatch("artikel/createUpdate", payload)
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.$swal({
                icon: "success",
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    UploadThumbnail() {
      const fd = new FormData();
      fd.append("thumbnail", this.form.thumbnail);
      fd.append("title", this.form.title);
      fd.append("content", this.form.content);
      fd.append("type", this.form.type);
      fd.append("category_id", this.form.category_id);
      if (this.id) {
        fd.append("id", this.form.id);
      }

      this.$store
        .dispatch("artikel/createUpdate", fd)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.ModalUploadThumbnail = false;
          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Thumbnail berhasil di" + this.activeAction,
          });
          this.resetForm();
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    Tambah() {
      if (
        this.form.title == null ||
        this.form.title == "" ||
        this.form.content == null ||
        this.form.content == "" ||
        this.form.type == null ||
        this.form.type == "" ||
        this.form.category_id == null ||
        this.form.category_id == ""
      ) {
        this.pesanGagal();
        return false;
      }
      // const payload = this.form;
      this.form.thumbnail = null;
      const fd = new FormData();
      fd.append("title", this.form.title);
      let content = this.form.content;
      content = content.replaceAll("style=", "style2=");
      fd.append("content", content);
      fd.append("type", this.form.type);
      fd.append("category_id", this.form.category_id);
      if (this.id) {
        fd.append("id", this.form.id);
      }

      this.$store
        .dispatch("artikel/createUpdate", fd)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.getData();
          this.displaySuccess({
            text: "Artikel berhasil di" + this.activeAction,
          });

          this.resetForm();
          this.Modal = false;
          // this.ModalEdit = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getData() {
      this.loading = true;
      const params = {
        filter: "title",
        search: this.filter != null ? this.filter : null,
        type: "article",
      };
      this.$store
        .dispatch("artikel/index", params)
        .then((response) => {
          this.loading = false;
          let artikel = response.data.data;
          this.artikel = artikel;
          this.totalRows = this.artikel.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    getDataKategoriArtikel() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };
      this.$store
        .dispatch("kategoriArtikel/getData", payload)
        .then((response) => {
          let category_id = response.data.data;
          category_id.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optcategory_id = category_id;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
  },
  created() {},
  async mounted() {
    this.getData();
  },
};
</script>

<style></style>
